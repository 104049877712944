import React from 'react';
import '../../styles/gst.css';
import Testimonial from '../../components/Testimonial';
import FAQAccord from '../../components/GFlush';
import Layout from '../../components/Layout';
import { Seo } from '../../components/seo';
import Counter from '../../components/Counter';
import Guidance from '../../components/GSTTemp/Guidance';
// import Hero from "../../components/Hero";
import AboutWithForm from '../../components/GSTTemp/AboutWithForm';
import ScrollNav from '../../components/GSTTemp/ScrollNav';
import ImgContent from '../../components/GSTTemp/ImgContent';
import ContentForm from '../../components/GSTTemp/ContentForm';
import Cta from '../../components/Cta';
import SliderContent from '../../components/GSTTemp/SliderContent';
import ContentImg from '../../components/GSTTemp/ContentImg';
import Package from '../../components/GSTTemp/Package';

export default function TaxDeductionRegistration() {
  /* Slider */
  // const reg12Slider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 30,
  //     background: `linear-gradient(139.4deg, rgb(198, 243, 242) 28.54%, rgb(181, 201, 253) 104.43%)`,
  //     header: `Secure and fast 12AA registration`,
  //     content: `Secure Society Registration.`,
  //     image: "/imgs/business/llp.png",
  //     alt_tag: "Online 12AA Registration Near Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `12AA Registration`,
    buyBtnLink: `#pricing-buy`,
    price: `14999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };

  /* About Data With Form*/
  const reg12AboutData = {
    header: `Online 12AA Registration`,
    sub_title: `Best 12AA Tax Deduction Service providers in Hosur.`,
    content_paragraph: [
      `The purpose of 12AA registration is to obtain an income tax exemption. Once this registration is complete, 
      the association's entire revenue cannot be taxed. The Commissioner of Income-tax Department, who holds the 
      jurisdiction establishment, will decide whether or not the association can tax its entire revenue.`,

      // `All NGO's are eligible for income tax exemption, but only those who are aware of it and can profit from it
      // are eligible.`,
    ],
  };

  /* Scroll Nav Data */
  const reg12ScrollId = [
    {
      id: `#12procedure`,
      heading: `Procedure`,
    },
    {
      id: `#12eligibility`,
      heading: `Eligibility`,
    },
    {
      id: `#12Cancelation`,
      heading: `Cancelation`,
    },
    {
      id: `#aa-documents`,
      heading: `Documents`,
    },
  ];

  /* Img Content Component Data */
  const reg12IcData = {
    id: '12procedure',
    mt_div: '',
    mt_img: '',
    header: '12AA Registration Procedures',
    image: '/imgs/registration/ngo/12aa-registration.png',
    alt_tag: 'non-government organization',

    Paragraph: `The Commissioner will ask you to provide additional reports if necessary,The request for additional document verification will aid in proving the legitimacy of the organization's operations,
    The Commissioner only needs to pass a solicitation for the enrollment cycle to begin in the event that they are satisfied with the application and choose to register the Trust or Institution under Section 12AA.`,
    points: [
      {
        head: '',
        content: `According to Section 12AA(2), the registering authority must decide whether to grant or deny 
        a registration request before a half-year period has passed since the end of the month in which the 
        application was received.`,
        icon: true,
      },
      {
        head: '',
        content: `In India, everything that is deemed a 12AA registration lasts one to three months. However, 
        once a Trust obtains registration, it is valid for the duration of the Trust and does not require 
        recharging.`,
        icon: true,
      },
      {
        head: 'What is Form 10?',
        content: `Organizations that are willing to register under Section 12AA must submit Form 10A. 
        The registration process has been made available online, along with the filing of forms 10A and 12AA.
         It must be made possible with the signatory's computerized signature.`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const reg12CfData = {
    id: '12eligibility',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'Minimum requirements for online 12AA registration in India',
    body: [
      {
        head: ``,
        points: `One of the requirements for inclusion under Section 12AA is that the association's
         existence is motivated by the accomplishment of charitable work as defined by the Income Tax Act. `,
        icon: true,
      },
      {
        points: `Giving education, providing medical assistance to the underprivileged, and carrying out
         deforestation-related operations are all examples of charity deeds.`,
        icon: true,
      },
      {
        points: `The services provided under this sector are restricted if the assessee is involved 
        in activities like exchange or trading.`,
        icon: true,
      },
      {
        points: `In these situations, only the candidates whose trade activity receipts make up less 
        than 20% of the assessor's total receipts are permitted to register.`,
        icon: true,
      },
      {
        points: `Family or private trusts are ineligible to apply for Section 12AA registration.`,
        icon: true,
      },
      {
        points: `If the assesses application has been rejected, he must submit an application together 
        with a self-certified copy of the order of rejection.`,
        icon: true,
      },
      {
        points: `Administrative specialists will examine whether any benefit thinking process is 
        involved in guiding the exercises before granting any registration certificate under 
        Section 12AA; if not, registration under Section 12AA is permitted.`,
        icon: true,
      },
    ],
  };

  /*  Content Img Component Data */
  const reg12CiData = {
    id: '12Cancelation',
    background: 'main-content',
    mt_div: '',
    header: 'Cancellation Of 12AA Registration',
    paragraph: `The Principal Commissioner or the Commissioner of Income-tax may cancel the registration 
    under the following sub-sections when a trust or foundation has been granted registration under Section
     12AA(1)(b) or has obtained registration as it existed prior to the Finance (No 2) Act, 1996.`,
    points: [
      {
        head: 'Cancellation of Registration under Section 12AA (4)',
        content: `Under section 12AA(4) of the Income-charge Act, part 13(1) is material as its exercises are being 
        done in such a manner, that it is for the preferred position of a particularly strict network or station (if it is set up subsequent to the start of the Act).`,
        icon: true,
      },
      {
        content: `Any compensation or property of the trust is applied with the consent of specific parties, 
        such as the trust's owner, trustees, etc.;`,
        icon: true,
      },
      {
        content: `Its advantages constrain assets to certain modes.`,
        icon: true,
      },
      {
        content: `By that time, the Principal Commissioner or the Commissioner may choose to approve a request 
        that is scratched off the list of trusts and establishments and is documented as a hard copy.`,
        icon: true,
      },
    ],
    image: '/imgs/business/dsc1-lib.png',
    alt_tag: 'Intellectual property dispute',
  };

  /* ImgSlider Data */
  const reg12Images = [
    '/imgs/registration/ngo/80g-registration.png',
    '/imgs/registration/ngo/society.png',
    '/imgs/registration/ngo/trust.png',
  ];
  /*  Slider Content Component Data */
  const reg12SCData = {
    id: 'aa-documents',
    mt_img: 'mt-5',
    background: [],
    mt_div: 'mt-3',
    header: 'Required Documents For 12AA Registration',
    paragraph: [
      `Along with Form 10A, applicants for registration under category 12AA must submit the following documents,
      The instrument's self-certified copy that was used to establish the institution or create a trust must be 
      presented.Financial report for the trust's/most institution's recent three financial years.`,
    ],
    content: [
      {
        head: '',
        points: `Give a copy of the registration that was made with the relevant body that has been 
        self-attested.`,
        icon: true,
      },
      {
        points: `The Registrar of Companies, the Registrar of Firms and Societies, or the Registrar of 
        Public Trusts may be the pertinent body.`,
        icon: true,
      },
      {
        points: `When adopting the document or changing the entity's aim, a self-certified copy of the 
        document must be submitted as evidence.`,
        icon: true,
      },
      {
        points: `If the assesses application has been rejected, he must submit an application together
         with a self-certified copy of the order of rejection.`,
        icon: true,
      },
    ],
    images: reg12Images,
  };

  var FAQ_data = [
    {
      header: `What makes 12A and 12AA different from one another?`,
      body: [
        {
          content: `Section 8 Companies, Societies, and Trusts can apply for
          enrollment under Section 12A of the Income Tax Act to guarantee
          avoidance under the Income Tax Act's 1961 provided certain
          circumstances are met, as well as Section 12AA arrangements with
          an online framework for the choosing of the trust.`,
          icon: false,
        },
      ],
    },

    {
      header: 'Who can ensure exclusion under Section 11?',
      body: [
        {
          content: `Regardless, the cash from a religious trust or establishment may
          be used to support a certain strict group or position. To open
          severe trusts justly and not to trust for private, exacting ends
          is prohibited under Section 11.`,
          icon: false,
        },
      ],
    },
    {
      header: ' What is the difference between 12A and 12AA? ',
      body: [
        {
          content: `Trust, Society and Section 8 Company can look for registration under Section 12A of Income Tax Act
           to guarantee exclusion under Income Tax Act’ 1961, if certain conditions are fulfilled. Section 12A deals 
           with enrolment of trust and Section 12AA deals with online system for enlistment of trust.`,
          icon: false,
        },
      ],
    },
    {
      header: ' What is a Trust? ',
      body: [
        {
          content: `Under general law, a 'trust' is characterized as a commitment joined to the responsibility for, 
          and emerging out of the certainty rested by the creator of the trust in the trustees. In the I-T Act, be 
          that as it may, the word has been utilized from a more extensive perspective to incorporate some other 
          legitimate commitment, even where the lawful necessities for making of a trust are not carefully met.`,
          icon: false,
        },
      ],
    },
    {
      header:
        ' What are the documents required during Section 12AA registration? ',
      body: [
        {
          content: `Instrument’s self-certified copy which was used in the process of creating trust or establishing 
          the institution shall be submitted.`,
          icon: true,
        },
        {
          content: `The foundation or trust may have been made in any case than by method for drafting and registering 
          an instrument. In such cases, a self-attested copy of the document confirming the creation of the trust, or 
          foundation of the institution ought to be submitted to the Income Tax Department.`,
          icon: true,
        },
        {
          content: `Provide a self-attested copy of the registration, which was made with the pertinent body. The 
          relevant body might be the Registrar of Companies, the Registrar of Firms and Societies or Registrar of 
          Public Trusts.`,
          icon: true,
        },
        {
          content: `A self-certified copy of the document which serves as an evidence at the time of adoption 
          or during alteration of the objective of the entity shall be submitted.`,
          icon: true,
        },
        {
          content: `Financial report of the trust/institution for maximum three preceding financial year.`,
          icon: true,
        },
        {
          content: `Note on activities which entity perform`,
          icon: true,
        },
        {
          content: `There a few cases which may force Income Tax Department to cancel the registration issued 
          under this section. Though once the assessee has resolved the issue he can file for the subsequent 
          application. In such a scenario it is essential for the applicant to submit a self-certified copy of 
          the existing order issuing registration.`,
          icon: true,
        },
        {
          content: `In case assessee application has been dismissed, he needs to attach a self-certified 
          copy of the order of rejection with the application.`,
          icon: true,
        },
      ],
    },
    {
      header: `What exactly is a 12A Certificate?`,
      body: [
        {
          content: `In spite of the fact that references to trusts can be found in
          sections 2(15), 10(23C), 11, 12A, 12AA, 13, 115BBC, 115TD to
          115TF, 160, 161, 164, and 164A, trusts are fundamentally not
          secured under the definition of an individual under section 2(31).
          This is because obligation law wants to use the possibility of
          operator assesses to trouble the trust.`,
          icon: false,
        },
      ],
    },
    {
      header: ` What are the benefits of 12AA registration? `,
      body: [
        {
          content: `The fund which you are planning to use for the charitable or religious purpose will be 
          regarded as income application. In layman term, income application is considered as an expense 
          which is incurred by the trust on charity or on religious purpose.`,
          icon: true,
        },
        {
          content: `The final income received will be exempted from tax`,
          icon: true,
        },
        {
          content: `Organization or person who is registered under this section can take the advantage 
          of accumulation of income which cannot exceed 15% for the charitable or religious purposes.`,
          icon: true,
        },
        {
          content: `Section 11(2) consider accumulation of income as application of income hence it is 
          not included in the total income.`,
          icon: true,
        },
        {
          content: `NGOs enjoy the benefit of receiving multiple permits from government and other agencies. 
          There are agencies that provide financial support to NGOs and these agencies usually prefer to make 
          grant to 12AA registered NGO’s.`,
          icon: true,
        },
        {
          content: `Registration done under Section 12AA is a onetime process. Once the registration is done, it 
          remains valid till the date of cancellation of the registration. 12AA registration does not need to be 
          renewed hence it can be considered as a benefit avail by the NGOs.`,
          icon: true,
        },
      ],
    },

    {
      header: `What are the requirements for obtaining 12AA registration?`,
      body: [
        {
          content: `One of the requirements for inclusion under Section 12AA is that
          the association's existence is motivated by the accomplishment of
          charitable work as defined by the Income Tax Act.`,
          icon: true,
        },
        {
          content: `Family or private trusts are ineligible to apply for Section 12AA
          registration.`,
          icon: true,
        },
        {
          content: `Administrative specialists will examine whether any benefit
          thinking process is involved in guiding the exercises before
          granting any registration certificate under Section 12AA,if not,
          registration under Section 12AA is permitted.`,
          icon: true,
        },
      ],
    },
    {
      header: ` What is the difference between 80G and 12A registration? `,
      body: [
        {
          content: `If an NGO gets itself enrolled under Section 80G, at that point the individual or the association 
          making a donation to the NGO will get a deduction of half from his/its assessable pay. On the off chance 
          that a ngo gets enlisted under 12AA and 80g, at that point just it is material for any administration
           financing. A recently enlisted NGO can likewise apply for 80g registration.`,
          icon: false,
        },
      ],
    },
    {
      header: ` What is Form 10A? `,
      body: [
        {
          content: `Organizations who are willing to get registered under Section 12AA needs to file form 10A. 
          Organizations such as Charitable Trusts, Religious Trusts, Societies and Section 8 Companies are eligible 
          to apply for Section 12AA registration. 12AA registration procedure along with Form 10A filing has been
           made online and can only be made possible with the digital signature of the signatory.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='12AA Registration'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={reg12Slider} /> */}
          <Package packageData={PackageCompData} />
          <AboutWithForm about_content={reg12AboutData} />

          <Counter />

          <ScrollNav scroll_data={reg12ScrollId} />

          <ImgContent item={reg12IcData} />

          <ContentForm CFSection_data={reg12CfData} />

          <SliderContent ScSection_data={reg12SCData} />

          <ContentImg CISection_data={reg12CiData} />

          <Cta />

          <Guidance />

          <Testimonial />
          <FAQAccord items={FAQ_data} />
        </main>
      </Layout>
    </div>
  );
}
